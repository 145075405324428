.list {
  position: relative;
  margin: 1.25rem 0 0;
  padding: 2.1875rem 0 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.125rem;
  border-top: 1px solid var(--border);
  list-style: none;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 1.25rem;
    border-left: 1px solid var(--border);
  }

  &::after {
    left: calc(33.333% - 0.5rem);
  }
  &::before {
    right: calc(33.333% - 0.5rem);
  }
}

.item > * {
  height: 100%;
}

.companiesContainer {
  position: relative;
  padding: 1.5625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--vacancy);

  svg {
    display: block;
  }
}

.line {
  height: 1px;
  width: 3.125rem;
  margin-top: 1.0625rem;
  background-color: var(--border);
}

.companiesTitle {
  margin: 1.25rem 0 0;
  font-size: 1.625rem;
  line-height: 1.3;
  font-weight: 500;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.companiesText {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
}

.companies {
  margin-top: 1.5625rem;
  display: flex;
  column-gap: 0.5rem;
}

.company {
  position: relative;
  z-index: 1;
  width: 3.3125rem;
  height: 3.3125rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--avatar);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:nth-child(5),
  &:nth-child(6) {
    display: none;
  }
}

@media (max-width: 83.5rem) {
  .list {
    gap: 0.9375rem;

    &::after {
      left: calc(33.333% - 0.125rem);
    }
    &::before {
      right: calc(33.333% - 0.1875rem);
    }
  }
}

@media (max-width: 70rem) {
  .list {
    grid-template-columns: repeat(2, 1fr);

    &::after,
    &::before {
      content: none;
    }

    &--hasCompanies > :nth-child(2) {
      display: none;
    }
  }
}

@media (max-width: 62rem) {
  .list {
    padding: 0;
    border: none;
  }
}

@media (max-width: 48rem) {
  .list {
    display: block;

    > :not(:first-child) {
      margin-top: 1.25rem;
    }
  }

  .companiesContainer {
    height: 14.75rem;
    padding: 1.25rem;
    box-sizing: border-box;
  }

  .line {
    margin-top: 1.25rem;
  }

  .companiesTitle {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .companiesText {
    font-size: 0.875rem;
    line-height: 1.28;
  }

  .companies {
    margin-top: 0.9375rem;
    column-gap: 0.375rem;
  }

  .company {
    width: 2.5rem;
    height: 2.5rem;

    &:nth-child(5),
    &:nth-child(6) {
      display: block;
    }
  }
}
